<div class="content-padding">
        <div>
           <a onclick="goBack()" style="cursor: pointer">Torna Indietro</a> oppure <a href='/'>Torna alla Welcome Page</a>
        </div>
        <h2>Informativa sull'uso dei cookie per il sito SPRINT</h2>
        <p>Questa informativa sull'utilizzo dei cookie è resa all'utente in attuazione del provvedimento del Garante per la protezione dei dati personali dell'8 maggio 2014 'Individuazione delle modalità semplificate per l'informativa e l'acquisizione del consenso per l'uso dei cookie' e nel rispetto dell'art. 13 del Codice privacy (D.Lgs. n. 196/2003). Questo sito utilizza cookie tecnici, propri e di terze parti, per consentire la corretta navigazione. In questa pagina sono riportate informazioni sull'uso dei cookie e di tecnologie similari, su come sono utilizzati e su come gestirli.</p>
        <h3>Considerazioni preliminari</h3>
        <p>(Dal <a href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/3118884" target="_blank">testo del Garante</a>)</p>
        <p>I cookie sono stringhe di testo di piccole dimensioni che i siti visitati dall'utente inviano al suo terminale (solitamente al browser), dove vengono memorizzati per essere poi ritrasmessi agli stessi siti alla successiva visita del medesimo utente. Nel corso della navigazione su un sito, l'utente può ricevere sul suo terminale anche cookie che vengono inviati da siti o da web server diversi (c.d. 'terze parti'), sui quali possono risiedere alcuni elementi (quali, ad esempio, immagini, mappe, suoni, specifici link a pagine di altri domini) presenti sul sito che lo stesso sta visitando.</p>
        <p>I cookie, solitamente presenti nei browser degli utenti in numero molto elevato e a volte anche con caratteristiche di ampia persistenza temporale, sono usati per differenti finalità: esecuzione di autenticazioni informatiche, monitoraggio di sessioni, memorizzazione di informazioni su specifiche configurazioni riguardanti gli utenti che accedono al server, ecc.</p>
        <p>Al fine di giungere a una corretta regolamentazione di tali dispositivi, è necessario distinguerli - posto che non vi sono delle caratteristiche tecniche che li differenziano gli uni dagli altri - proprio sulla base delle finalità perseguite da chi li utilizza. In tale direzione si è mosso, peraltro, lo stesso legislatore, che, in attuazione delle disposizioni contenute nella direttiva 2009/136/CE, ha ricondotto l'obbligo di acquisire il consenso preventivo e informato degli utenti all'installazione di cookie utilizzati per finalità diverse da quelle meramente tecniche (cfr. art. 1, comma 5, lett. a), del d. lgs. 28 maggio 2012, n. 69, che ha modificato l'art. 122 del Codice).</p>
        <p>Al riguardo, e ai fini del presente provvedimento, si individuano pertanto due macro-categorie: cookie 'tecnici' e cookie 'di profilazione'.</p>
        <h3>Tipologie di cookie</h3>
        <p style="font-style: italic">a. Cookie tecnici.</p>
        <p>I cookie tecnici sono quelli utilizzati al solo fine di 'effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della società dell'informazione esplicitamente richiesto dall'abbonato o dall'utente a erogare tale servizio' (cfr. art. 122, comma 1, del Codice).</p>
        <p>Essi non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal titolare o gestore del sito web. Possono essere suddivisi in cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web (permettendo, ad esempio, di realizzare un acquisto o autenticarsi per accedere ad aree riservate); cookie analytics, assimilati ai cookie tecnici laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso; cookie di funzionalità, che permettono all'utente la navigazione in funzione di una serie di criteri selezionati (ad esempio, la lingua, i prodotti selezionati per l'acquisto) al fine di migliorare il servizio reso allo stesso.</p>
        <p>Per l'installazione di tali cookie non è richiesto il preventivo consenso degli utenti, mentre resta fermo l'obbligo di dare l'informativa ai sensi dell'art. 13 del Codice, che il gestore del sito, qualora utilizzi soltanto tali dispositivi, potrà fornire con le modalità che ritiene più idonee.</p>
        <p style="font-style: italic">b. Cookie di profilazione</p>
        <p>I cookie di profilazione sono volti a creare profili relativi all'utente e vengono utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso nell'ambito della navigazione in rete. In ragione della particolare invasività che tali dispositivi possono avere nell'ambito della sfera privata degli utenti, la normativa europea e italiana prevede che l'utente debba essere adeguatamente informato sull'uso degli stessi ed esprimere così il proprio valido consenso.</p>
        <p>Ad essi si riferisce l'art. 122 del Codice laddove prevede che 'l'archiviazione delle informazioni nell'apparecchio terminale di un contraente o di un utente o l'accesso a informazioni già archiviate sono consentiti unicamente a condizione che il contraente o l'utente abbia espresso il proprio consenso dopo essere stato informato con le modalità semplificate di cui all'articolo 13, comma 3' (art. 122, comma 1, del Codice).</p>
        <h3>Durata dei cookie</h3>
        <p>I cookie utilizzati da questo sito terminano la propria validità al termine della sessione o dopo un tempo di inattività dell'utente fissato dal server.</p>
        <h3>Gestione dei cookie</h3>
        <p>SPRINT utilizza cookie tecnici, propri e di terze parti, per la gestione della sessione e la navigazione. Disattivando questi sarà possibile accedere e navigare solamente sulle pagine pubbliche di SPRINT.</p>
        <p>In generale l'utente può comunque decidere se mantenere attivi o disattivare i cookie utilizzando le impostazioni del proprio browser. Di seguito si forniscono i riferimenti alle istruzioni per disabilitare i cookie nei browser più diffusi, rese disponibili dai relativi servizi di supporto:</p>
        <ul>
           <li><a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank">Chrome</a></li>
           <li><a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank">Firefox</a></li>
           <li><a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer</a></li>
           <li><a href="http://help.opera.com/Windows/12.10/it/cookies.html" target="_blank">Opera</a></li>
           <li><a href="https://www.apple.com/legal/privacy/it/cookies/" target="_blank">Safari</a></li>
        </ul>
        <p>L'impostazione può essere definita in modo specifico per i diversi siti e applicazioni web.</p> 
        <br/><div>
           <a onclick="goBack()" style="cursor: pointer">Torna Indietro</a> oppure <a href='/'>Torna alla Welcome Page</a>
        </div>
</div>