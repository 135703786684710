<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
  <span>
    Attenzione! Si sta richiedendo la chiusura della procedura relativa all anno <b>{{account.anno}}</b>.
    <br/>
    Si ricorda che una volta effettuata la chiusura non sarà più possibile effettuare alcuna modifica.
    <br/>
    Se si è certi di volere procedere con la chiusura cliccare su <b>CONFERMA CHIUSURA</b>.
    <br/><br/>
  </span>
  <span *ngIf="data.chiusura.perc!=100">
    Si è scelto di procedere alla chiusura nonostante la percentuale di completamento timesheet sia <b>
      {{data.chiusura.perc|number:'.2-2'}}%</b>
    <br/>
    E' necessario quindi inserire una nota.
    <br/><br/>
  </span>

  <mat-form-field class="full-width-input" appearance="fill">
    <mat-label>Nota</mat-label>
      <textarea rows="14"  placeholder="nota" matInput #input maxlength="4000" [(ngModel)]="data.chiusura.nota" [required]="data.chiusura.perc!=100"></textarea>
      <mat-hint align="end">{{input.value?.length || 0}}/4000</mat-hint>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button  (click)="dismiss()">Annulla</button>
  <button
    mat-raised-button color="primary"
    [disabled]="!data.chiusura.nota && data.chiusura.perc!=100"
    (click)="chiusura()">
    CONFERMA CHIUSURA
  </button>
</mat-dialog-actions>
