import { ChangeDetectorRef, Component, ElementRef } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AccountService, AlertService, DbService } from '@app/_services'
import { FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { NewsFormDialog } from './news-form-dialog'
import { DateUtils } from '@app/_helpers'
import { HttpClient } from '@angular/common/http'
import { UploadDialog } from './upload-dialog'

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  notizie = []
  docs = []
  account
  data_inizio1
  data_fine1
  data_inizio2
  data_fine2

  titolo0 = new FormControl('')

  constructor(
    private db: DbService,
    private route: ActivatedRoute,
    private accountService: AccountService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private changeDetectorRefs: ChangeDetectorRef,
    private du: DateUtils,
    private http: HttpClient) {

  }

  openUpload(){
    let dialogConfig = {
      height: "calc(80% - 30px)",
      width: "calc(60% - 30px)",
      maxWidth: "60%",
      maxHeight: "80%"
    }
    const dialogRef = this.dialog.open(UploadDialog, dialogConfig)
    dialogRef.afterClosed()
      .subscribe()
  }

  ngOnInit() {
    this.account = this.accountService.accountValue
    this.loadData()
    this.loadDocs()
  }

  loadData() {
    this.route.paramMap
      .subscribe(params =>
        this.db.readAll('notizia', {})
          .subscribe(data => {
            this.notizie = data
            this.notizie.forEach(notizia => {
              notizia.data_inizio = this.du.stringToDate(notizia.data_inizio)
              notizia.data_fine = this.du.stringToDate(notizia.data_fine)
            })
          })
      )
  }

  loadDocs() {
    this.route.paramMap
      .subscribe(params =>
        this.db.readAll('upload', {})
          .subscribe(data => {
            this.docs = data
          })
      )
  }

  editHome(notizia) {
    let dialogConfig = {
      disableClose: true,
      autoFocus: true,
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%",
      data: {}
    }

    let dialogData = { news: this.notizie, notizia: {} }
    if (notizia) dialogData.notizia = { ...notizia }
    dialogConfig.data = dialogData

    const dialogRef = this.dialog.open(NewsFormDialog, dialogConfig)
    dialogRef.afterClosed()
      .subscribe(notizia => {
        if (notizia) {
          notizia.data_inizio = this.du.formatDate(notizia.data_inizio)
          notizia.data_fine = this.du.formatDate(notizia.data_fine)
          this.db.save('notizia', notizia)
            .subscribe({
              next: (data) => {
                this.loadData()
                this.changeDetectorRefs.detectChanges()
                this.alertService.success(data.esito)
              }
            })
        }
      })
  }

  scarica(doc) {
    let body = { path: doc.path, mimetype: doc.mimetype, filename: doc.originalname }
    this.http.post('/api/files/download', body, { responseType: 'blob' })
      .subscribe({
        next: (data: Blob) => {
          let blob = new Blob([data], { type: doc.mimetype })
          let link = document.createElement('a')
          link.target = '_blank'
          link.href = URL.createObjectURL(blob)
          link.setAttribute("download", doc.originalname)
          link.click()
        },
        error: (error) => this.alertService.error(error)
      })
  }


  deleteDoc(id) {
    this.db.delete('upload', id)
      .subscribe(data => { this.loadDocs() })
  }
}
