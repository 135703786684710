import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { AccountService } from '@app/_services';

@Component({
  templateUrl: 'chiusura-form-dialog.html',
  styleUrls: ['chiusura-form-dialog.css']
})
export class ChiusuraFormDialog implements OnInit {
  title = 'Chiusura edizione'
  account;
  constructor(
    public dialogRef: MatDialogRef<ChiusuraFormDialog>,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.account = this.accountService.accountValue
  }

  dismiss(): void {
    this.dialogRef.close()
  }

  chiusura() {
    this.dialogRef.close(this.data.chiusura)
  }
}
