import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class LoaderService {
    isLoading = new Subject<boolean>();
    requestsCount:number = 0
    show() {
        this.isLoading.next(true);
        this.requestsCount++
    }
    hide() {
      this.requestsCount--
      if (!this.requestsCount) this.isLoading.next(false);
    }
}
