<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
  <mat-form-field class="full-width-input" appearance="fill">
    <mat-label>Titolo</mat-label>
    <input
      matInput
      placeholder="Titolo"
      [(ngModel)]="data.notizia.titolo"
      maxlength="50" #titolo
      required />
     <mat-hint align="end">{{titolo.value?.length || 0}}/50</mat-hint>
  </mat-form-field>
  <mat-form-field class="full-width-input" appearance="fill">
    <mat-label>Testo</mat-label>
      <textarea rows="14"  placeholder="testo" matInput #input maxlength="4000" [(ngModel)]="data.notizia.testo" required></textarea>
      <mat-hint align="end">{{input.value?.length || 0}}/4000</mat-hint>
  </mat-form-field>
  <mat-form-field *ngIf="data.notizia._id==4 || data.notizia._id==3" class="full-width-input" style="width:20%; margin-right: 10px;"  appearance="fill">
    <input matInput  [matDatepicker]="picker" placeholder="Data inizio" [(ngModel)]="data.notizia.data_inizio" required>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field  *ngIf="data.notizia._id==4 || data.notizia._id==3"  class="full-width-input" style="width:20%"  appearance="fill">
    <input matInput  [matDatepicker]="datepicker2" placeholder="Data fine" [(ngModel)]="data.notizia.data_fine">
    <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
    <mat-datepicker #datepicker2></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button  (click)="dismiss()">Annulla</button>
  <button
    mat-raised-button color="primary"
    [disabled]="!data.notizia.titolo || !data.notizia.testo"
    (click)="save()">
    Salva
  </button>
</mat-dialog-actions>
