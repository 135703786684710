export class Account {
    _id: number;
    ruolo: number;
    ruolor: number;
    codateneo: string;
    nome: string;
    cognome: string;
    email: string;
    dt_chiusura: string;
    anno : number;
}
