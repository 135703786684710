import { Component } from '@angular/core'
import { AccountService } from '@app/_services'
@Component({
  selector: 'user-dialog',
  templateUrl: './user-dialog.component.html'
})

export class UserDialogComponent {
  constructor(private accountService: AccountService) { }
  account;
  ngOnInit(): void {
    this.account = this.accountService.accountValue;
  }
}
