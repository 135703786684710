import { Component, OnInit, Input } from '@angular/core'
import { DbService, AlertService, AccountService } from '@app/_services'
import { first } from 'rxjs/operators'
import * as XLSX from 'xlsx'

@Component({
  selector: 'xls-downloader',
  templateUrl: 'xls-downloader.component.html',
  styleUrls: ['xls-downloader.component.css']
})

export class XlsDownloaderComponent implements OnInit {
  data: any
  columns = []

  constructor(private accountService: AccountService, private db: DbService, private alertService: AlertService) { }

  @Input() package: string
  @Input() metodo: string
  @Input() params: any
  @Input() template: any
  @Input() filename: string
  @Input() preview: boolean
  account = this.accountService.accountValue

  ngOnInit(): void {
    this.data = []
    this.columns = []
    if (this.preview) this.leggi()
  }

  leggi() {
    this.db.method(this.package, this.metodo, this.params)
      .pipe(first())
      .subscribe({
        next: data => {
          if (data?.length) {
            if (!this.preview)
              this.scarica(data)
            else {
              this.columns = Object.keys(data[0])
              this.data = data
            }
          }
          else
            this.alertService.warn('Nessun dato trovato')
        },
        error: error => this.alertService.error(error)
      })
  }

  scarica(dati) {
    let name = this.filename ? this.filename : 'export'
    let ws = XLSX.utils.json_to_sheet(dati)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, name)
    XLSX.writeFile(wb, name+'.xlsx')
  }

  download() {
    if (!this.preview)
      this.leggi()
    else if (this.data?.length)
      this.scarica(this.data)
  }

  download_tpl() {
    if (this.template?.length)
      this.scarica(this.template)
  }
}
