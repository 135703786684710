import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class AppVersion {
  private baseUrl = '/api/version'
  constructor(
     private http: HttpClient
  ) { }

  public read(): Observable<any>{
    const url : string = `${this.baseUrl}/getInfo` 

    return this.http.get<any>(url)
  }  
}