import { NgClass } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatNativeDateModule } from '@angular/material/core'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule} from '@angular/material/select/'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSliderModule } from '@angular/material/slider'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatChipsModule} from '@angular/material/chips'
import { MatTooltipModule} from '@angular/material/tooltip'
import { MatExpansionModule } from '@angular/material/expansion'


@NgModule( {
    imports: [MatBadgeModule,
              MatButtonModule,
              MatCardModule,
              MatCheckboxModule,
              MatDatepickerModule,
              MatDialogModule,
              MatFormFieldModule,
              MatGridListModule,
              MatIconModule,
              MatInputModule,
              MatListModule,
              MatMenuModule,
              MatNativeDateModule,
              MatPaginatorModule,
              MatProgressSpinnerModule,
              MatSelectModule,
              MatSidenavModule,
              MatSliderModule,
              MatSnackBarModule,
              MatSortModule,
              MatTableModule,
              MatTabsModule,
              MatToolbarModule,
              MatChipsModule,
              MatTooltipModule,
              MatExpansionModule],
    exports: [MatBadgeModule,
              MatButtonModule,
              MatCardModule,
              MatCheckboxModule,
              MatDatepickerModule,
              MatDialogModule,
              MatFormFieldModule,
              MatGridListModule,
              MatIconModule,
              MatInputModule,
              MatListModule,
              MatMenuModule,
              MatNativeDateModule,
              MatPaginatorModule,
              MatProgressSpinnerModule,
              MatSelectModule,
              MatSidenavModule,
              MatSliderModule,
              MatSnackBarModule,
              MatSortModule,
              MatTableModule,
              MatTabsModule,
              MatToolbarModule,
              MatChipsModule,
              MatTooltipModule,
              MatExpansionModule]
})
export class MaterialModule {}
