import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

export class DbService {
  private API_URL = '/api/db/'

  constructor(private http: HttpClient) { }

  public readAll(pkg: string, params?: object): Observable<any> {
    const url : string = `${this.API_URL}readAll/${pkg}`
    let httpp = new HttpParams()
    if (params)
      Object.keys(params).forEach(function(key) {
        httpp = httpp.append(key, params[key]);
      })
    return this.http.get<any>(url, { params: httpp })
  }

  public read(pkg: string, id: number): Observable<any> {
    const url = `${this.API_URL}read/${pkg}/${id}`
    return this.http.get<any>(url)
  }

  public create(pkg: string, params: object): Observable<any> {
    const url = `${this.API_URL}create/${pkg}`
    return this.http.post<any>(url, params)
  }

  public update(pkg: string, params: any): Observable<any> {
    const url = `${this.API_URL}update/${pkg}/${params._id}`
    return this.http.put<any>(url, params)
  }

  public save(pkg: string, params: any): Observable<any> {
    return params._id ? this.update(pkg, params) : this.create(pkg, params)
  }

  public delete(pkg: string, id: number): Observable<any> {
    const url = `${this.API_URL}delete/${pkg}/${id}`
    return this.http.delete<any>(url)
  }

  public method(pkg: string, mth: string, params?: object): Observable<any> {
    const url = `${this.API_URL}method/${pkg}/${mth}`
    const pars = params || {}
    return this.http.post<any>(url, pars)
  }
}