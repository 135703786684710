import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DbService, AlertService } from '@app/_services'
import { first } from 'rxjs/operators'

@Component({ selector: 'timesheet-cell', templateUrl: 'timesheet-cell.component.html',
            styleUrls: ['./timesheet-cell.component.css'] }
           )

export class TimesheetCellComponent implements OnInit {
  constructor(private db: DbService, private alertService: AlertService) { }
  @Output() finishUpdate = new EventEmitter()
  @Input() id_costo_det : number
  @Input() id_servizio : number
  @Input() percentuale : number

  valori = [0,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100]
  nome : string
  currVal : number

  ngOnInit(): void {
    this.nome = 'tscell_' + this.id_costo_det + '_' + this.id_servizio
    this.currVal = this.percentuale
  }

  onChange() {
    let pars = {
      id_costo_det: this.id_costo_det,
      id_servizio: this.id_servizio,
      percentuale: +this.currVal
    }
    this.db.method('timesheet', 'write', pars)
      .pipe(first()) 
      .subscribe({
        next: data => {
          this.alertService.success(data.esito)
          this.finishUpdate.emit(pars)
        },
        error: error => this.alertService.error(error)
      })
  }
}
