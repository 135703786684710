import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { DbService } from '@app/_services'
import { first } from 'rxjs/operators'

@Component({
  templateUrl: './logs-dialog.component.html',
})

export class LogsDialogComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = ['data', 'testo', 'cognome', 'nome', 'username']
  dataSource= new MatTableDataSource([])

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  tableCols: any = [
    { def: 'data', desc: 'Data' },
    { def: 'testo', desc: 'Testo' },
    { def: 'cognome', desc: 'Cognome' },
    { def: 'nome', desc: 'Nome' },
    { def: 'username', desc: 'E-mail' }
  ]

  constructor(private db: DbService) { }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator
  }

  ngOnInit(): void {
    // Messo timeout per misterioso errore ExpressionChangedAfterItHasBeenCheckedError
    // probabilmente causato dallo spinner della richiesta http
    setTimeout(() => {
      this.db.method('ateneo', 'logs')
        .pipe(first())
        .subscribe(data => {
          this.dataSource = new MatTableDataSource(data)
          this.dataSource.sort = this.sort
          this.dataSource.paginator = this.paginator

          /*this.dataSource.filterPredicate = (data, filter: string): boolean => {
            return ((filter==='*' ||
                    (data.descrizione.toLowerCase().includes(filter) ||
                      data.codice.toLowerCase().includes(filter) ||
                      data.descrizione_padre.toLowerCase().includes(filter) ||
                      data.codice_padre.toLowerCase().includes(filter))) &&
                    (!this.struttureSel?.length ||
                      this.struttureSel.includes(data._id)))
          }*/
        })
    })
  }
}
