import { Component, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertService, DbService } from '@app/_services';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  templateUrl: 'upload-dialog.html',
  styleUrls: ['upload-dialog.css']
})
export class UploadDialog implements OnInit {
  abcForm: FormGroup
  fileSelected: boolean = false
  selectedFiles: any;
  constructor(
    public dialogRef: MatDialogRef<UploadDialog>,
    public dialog: MatDialog,
    private router: Router,
    private alertService: AlertService,
    private db: DbService,
    private http: HttpClient
  ) {     this.abcForm = this.getForm()}

  getForm(): FormGroup {
    return new FormGroup({
      abcFile: new FormControl(),
      abcFileDesc: new FormControl()
    })
  }
  dismiss(): void {
    this.dialogRef.close()
  }
  ngOnInit() {
  }

  selectFile(event) {
    this.fileSelected=true
    this.selectedFiles = event.target.files;
  }
  upload() {
    let fileCount: number = this.selectedFiles.length;

    if (fileCount > 0) {
      let formData = new FormData();
      formData.append('abcfile', this.selectedFiles.item(0));
      this.http.post('/api/files/upload', formData)
        .subscribe({
          next: (upl: any) => {
            upl.descrizione = this.abcForm.get('abcFileDesc').value;
            this.db.save('upload', upl)
              .subscribe({
                next: (data) => {
                  // Faccio reload della route per resettare l'input file
                  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                  this.router.navigate([this.router.url]);
                  this.alertService.success('Il documento è stato caricato correttamente')
                  this.dismiss();
                },
                error: error => { this.alertService.error(error); }
              });
          },
          error: (error) => this.alertService.error(error)
        });
    }
  }

}
