import { Component, OnInit } from '@angular/core'
import { AppVersion } from '@app/_helpers'

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.css']
})
export class FooterComponent implements OnInit {
  version
  loading: boolean = true
  constructor(private appVersion: AppVersion) { }

  ngOnInit() {
    this.appVersion.read().subscribe(data => {
      this.version=data
      this.loading=false
    })
  }
}
