import { AfterViewInit, Component, ViewChild, OnInit, OnDestroy } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { AccountService, DbService, AlertService } from '@app/_services'
import { first } from 'rxjs/operators'
import { MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'

@Component({
  templateUrl: './simula-dialog.component.html',
})

export class SimulaDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['ateneo', 'cognome', 'nome', 'username', 'ruolo', 'sel']
  dataSource = new MatTableDataSource([])
  account = this.accountService.accountValue
  public searchText: string
  public searchModelChanged: Subject<string> = new Subject<string>()
  private searchModelChangeSubscription: Subscription

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  tableCols: any = [
    { def: 'ateneo', desc: 'Ateneo' },
    { def: 'cognome', desc: 'Cognome' },
    { def: 'nome', desc: 'Nome' },
    { def: 'username', desc: 'E-mail' }
  ]

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    private db: DbService,
    private router: Router,
    public dialogRef: MatDialogRef<SimulaDialogComponent>
  ) {  }

  ngOnInit() {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText
        this.cerca()
      })
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator
  }

  ngOnDestroy() {
    this.searchModelChangeSubscription.unsubscribe()
  }

  cerca() {
    if (this.searchText && this.searchText.length > 2)
      this.db.method('utente', 'simulabili', { text: this.searchText })
        .pipe(first())
        .subscribe(data => {
          this.dataSource = new MatTableDataSource(data)
          this.dataSource.sort = this.sort
          this.dataSource.paginator = this.paginator
        })
  }

  simula(id) {
    this.accountService
      .simula(id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success('Operazione eseguita')
          this.dialogRef.close()
          // Faccio reload della route per applicare i nuovi diritti
          this.router.routeReuseStrategy.shouldReuseRoute = () => false
          this.router.navigate([this.router.url])
        },
        error: error => { this.alertService.error(error) }
    })
  }
}
