import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'

@Component({
  templateUrl: 'news-form-dialog.html',
  styleUrls: ['news-form-dialog.css']
})
export class NewsFormDialog implements OnInit {
  title = 'Modifica Notizia'
  constructor(
    public dialogRef: MatDialogRef<NewsFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  dismiss(): void {
    this.dialogRef.close()
  }

  save() {
    if (!this.data.notizia.titolo || !this.data.notizia.testo) return

    this.dialogRef.close(this.data.notizia)
  }
}
