import { Component, ViewChild } from '@angular/core'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { AccountService, DbService } from './_services'
import { Account } from './_models'
import { MatSidenav } from '@angular/material/sidenav'
import { MatDialog } from '@angular/material/dialog'
import { UserDialogComponent } from './user-dialog.component'
import { LogsDialogComponent } from './logs-dialog.component'
import { SimulaDialogComponent } from './simula-dialog.component'
import { Router } from '@angular/router'
import { AppVersion } from '@app/_helpers'

@Component({ selector: 'app', templateUrl: 'app.component.html',
styleUrls: ['./app.component.css'] })
export class AppComponent {
  @ViewChild(MatSidenav) sidenav: MatSidenav
  version
  isShowing = false
  utenteSimulato: any
  isActive(){
    return this.router.url.includes('/gestione/')
  }
  opened: boolean = true

  account: Account

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    )

  constructor(
    private breakpointObserver: BreakpointObserver,
    private db: DbService,
    private accountService: AccountService,
    private router: Router,
    private appVersion: AppVersion,
    public dialog: MatDialog) {
      this.appVersion.read().subscribe(data => this.version = data)

      this.accountService.account.subscribe(x =>  {
        this.account = x
        if (this.isAdmin())
          this.db.method('utente', 'whoami')
            .subscribe(data => {
              this.utenteSimulato = data
            })
      })
  }

  openDialog() {
    this.dialog.open(UserDialogComponent)
  }

  openSimula() {
    let dialogConfig = {
      disableClose: true,
      autoFocus: true,
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%"
    }

    this.dialog.open(SimulaDialogComponent, dialogConfig)
  }

  openLogs() {
    let dialogConfig = {
      disableClose: true,
      autoFocus: true,
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%"
    }

    this.dialog.open(LogsDialogComponent, dialogConfig)
  }

  isAdmin() {
    if (this.account) {
      if (this.account.ruolor) return this.account.ruolor===20

      return this.account.ruolo===20
    }
    return false
  }

  logout() {
    this.accountService.logout()
  }
}
