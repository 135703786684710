<div class="content-padding">
  <div>
    <h2>Riepilogo</h2>
  </div>
  <div *ngIf="account.ruolo==0 && timesheet[3]?.perc>84" style="width: 100%; text-align: right;">
      <button *ngIf="!account?.dt_chiusura"  mat-raised-button color="primary" matTooltip="Chiusura" (click)="chiusura(timesheet[3]?.perc)" class="button-margin">Chiudi</button>
  </div>
  <div *ngIf="account?.dt_chiusura" class="alert-warning"> La chiusura è stata eseguita in data {{account?.dt_chiusura}}</div>
  <div *ngIf="account.ruolo==0 && timesheet[3]?.perc<85 && !account?.dt_chiusura" class="alert-error"> Attenzione! Sono stati rilevati servizi errati o in elaborazione. La chiusura non è permessa.</div>

  <mat-expansion-panel class="filter-box">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon class="filter-icon">search</mat-icon>
        Filtri
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field class="filter-multiselect" appearance="fill">
      <mat-label>Strutture</mat-label>
      <mat-select  [formControl]="struttureSel" multiple>
        <mat-option  *ngFor="let strutt of struttureList" [value]="strutt">{{strutt.codice}} - {{strutt.descrizione}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="account.ruolo==0" class="filter-multiselect" appearance="fill">
      <mat-label>Responsabile</mat-label>
      <mat-select  [formControl]="responsabileSel" multiple>
        <mat-option  *ngFor="let resp of responsabiliList" [value]="resp">{{resp.cognome}} {{resp.nome}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" matTooltip="Filtra" (click)="applyFilter()" class="button-margin">Filtra</button>
  </mat-expansion-panel>

  <mat-expansion-panel  [expanded]="true" class="chart-box">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon class="filter-icon">pie_chart</mat-icon>
        Grafici
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-grid-list cols="4" rowHeight="6:4">
      <mat-grid-tile *ngFor="let graf of graphList">
          <div>
            <canvas baseChart
              [data]="graf.doughnutChartData"
              [labels]="doughnutChartLabels"
              [chartType]="doughnutChartType"
              [colors]="doughnutChartColor"
              [options]="graf.options">
            </canvas>
          </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-expansion-panel>

  <table mat-table class="full-width-table" [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="title" class="totali">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let time" style="text-align: left !important;"> <b>{{time.titolo}}</b></td>
    </ng-container>
    <ng-container matColumnDef="num">
      <th mat-header-cell *matHeaderCellDef> N. Timesheet </th>
      <td mat-cell *matCellDef="let time"> {{time.totali}}</td>
    </ng-container>
    <ng-container matColumnDef="err">
      <th mat-header-cell *matHeaderCellDef> Errati </th>
      <td mat-cell *matCellDef="let time">
          <mat-chip  [ngClass]="{ 'ko': time.err > 0, 'empty': time.err==0}">{{time.err}}</mat-chip>
      </td>
    </ng-container>
    <ng-container matColumnDef="completi">
      <th mat-header-cell *matHeaderCellDef> Completati </th>
      <td mat-cell *matCellDef="let time">
          <mat-chip [ngClass]="{ 'ok': time.completi > 0, 'empty': time.completi==0}">{{time.completi}}</mat-chip>
      </td>
    </ng-container>
    <ng-container matColumnDef="elab">
      <th mat-header-cell *matHeaderCellDef> In Elaborazione </th>
      <td mat-cell *matCellDef="let time">
          <mat-chip [ngClass]="{ 'so': time.parziali > 0, 'empty': time.parziali==0}">{{time.parziali}} </mat-chip>
      </td>
    </ng-container>
    <ng-container matColumnDef="vuoti">
      <th mat-header-cell *matHeaderCellDef> Vuoti </th>
      <td mat-cell *matCellDef="let time">
        <mat-chip-list> </mat-chip-list>
          <mat-chip [ngClass]="{ 'empty': time.vuoti==0}">{{time.vuoti}} </mat-chip>
      </td>
    </ng-container>
    <ng-container matColumnDef="completamento">
      <th mat-header-cell *matHeaderCellDef> Completamento </th>
      <td mat-cell *matCellDef="let time">
        <mat-chip [ngClass]="{ 'ok': time.perc==100, 'empty': time.perc!=100}">
          <span *ngIf="!isNumber(time.perc)">{{time.perc|number:'.2-2'}}%</span>
          <span *ngIf="isNumber(time.perc)">0%</span>
        </mat-chip>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row [ngClass]="{'totali': row.titolo == 'Totali'}" *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">Nessun risultato recuperato</td>
    </tr>
  </table>




</div>
