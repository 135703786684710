<div class="fixActionRow">
<h2 mat-dialog-title>Logs</h2>
<br/>
<mat-dialog-content  class="dialog-content-height">
  <table mat-table class="full-width-table" [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let col of tableCols" matColumnDef="{{col.def}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{col.desc}}</th>
      <td mat-cell *matCellDef="let row">{{row[col.def]}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">Nessun risultato recuperato</td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
</mat-dialog-content>
<div class="spacer"></div>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" mat-dialog-close>Chiudi</button>
</mat-dialog-actions>
</div>
<app-loader></app-loader>
