<!--Duplico il codice perchè nel caso in cui sei loggato e fai F5 altrimenti fa vedere la pagina di
    DA SISTEMARE QUANDO ABBIAMO TEMPO-->
<div class="app-container" [ngClass]="{ 'bg-light': account }" *ngIf="!account">
      <alert></alert>
      <app-loader></app-loader>
      <!-- main router outlet -->
      <router-outlet></router-outlet>
</div>

<div class="app-container" [ngClass]="{ 'bg-light': account }" *ngIf="account">
  <mat-toolbar color="primary">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <img src="assets/imgs/logo-topbar.svg" style="padding-right: 20px;padding-left: 10px;"/>
    <span>Sprint - Activity Based Costing</span>
    <span class="fill-space"></span>
    <span style="font-size: 17px;" class="pull-right">Edizione: {{account.anno}}</span>
    <span class="fill-space"></span>
    <button
      mat-icon-button
      (click)="openLogs()"
      aria-label="Logs"
      matTooltip="Logs degli errori">
      <mat-icon>notification_important</mat-icon>
    </button>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Profilo utente"
      matTooltip="Profilo utente">
      <mat-icon>perm_identity</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button matTooltip="Informazione utente" mat-menu-item (click)="openDialog()">
        <mat-icon>info</mat-icon>
        <span>{{account.cognome}} {{account.nome}}</span>
      </button>
      <mat-divider  *ngIf="isAdmin()"></mat-divider>
      <mat-list class="menu-list"  *ngIf="isAdmin() && account.ruolor"  role="list">
        <mat-list-item role="listitem"><mat-icon >supervised_user_circle</mat-icon>  Utente simulato</mat-list-item>
        <mat-list-item role="listitem">{{utenteSimulato?.cognome}} {{utenteSimulato?.nome}}</mat-list-item>
        <mat-list-item role="listitem">{{utenteSimulato?.ateneo}}</mat-list-item>
      </mat-list>
      <button *ngIf="isAdmin()" matTooltip="Simulazione utente" mat-menu-item (click)="openSimula()">
        <mat-icon>transfer_within_a_station</mat-icon>
        <span *ngIf="account.ruolor">Interrompi/</span><span>Simula</span>
      </button>
      <mat-divider></mat-divider>
      <button matTooltip="Logout" (click)="logout()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar>

  <mat-sidenav-container id="container" fullscreen>
    <mat-sidenav mode="side" [(opened)]="opened">
      <mat-nav-list>
        <a mat-list-item routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
        <a mat-list-item *ngIf="account?.ruolo < 20" routerLink="/timesheet" routerLinkActive="active"
          class="nav-item nav-link">Timesheet</a>
        <mat-list-item *ngIf="account?.ruolo ==0 || account?.ruolo ==1" [ngClass]="{'submenu-active':isActive()}"
          (click)="isShowing = !isShowing" class="parent">
          <span class="full-width">Gestione</span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : !isShowing}">expand_less</mat-icon>
        </mat-list-item>
        <div
          class="submenu"
          [ngClass]="{'expanded' : isShowing, 'submenu-active':isActive()}"
          *ngIf="isShowing && (account?.ruolo ==0 || account?.ruolo ==1)">
          <a *ngIf="account?.ruolo ==0"
            mat-list-item routerLink="/gestione/strutture"
            routerLinkActive="active"
            class="nav-item nav-link">
            Strutture
          </a>
          <a
            mat-list-item routerLink="/gestione/utenti"
            routerLinkActive="active"
            class="nav-item nav-link">
            Utenti
          </a>
          <a *ngIf="account?.ruolo==0"
            mat-list-item routerLink="/gestione/avvia"
            routerLinkActive="active"
            class="nav-item nav-link">
            Avvia edizione
          </a>
          <!--a *ngIf="account?.ruolo ==0"
            mat-list-item
            routerLink="/gestione/dati"
            routerLinkActive="active"
            class="nav-item nav-link">
            Dati
          </a-->
        </div>
        <a mat-list-item *ngIf="account?.ruolo==0 || account?.ruolo==1" routerLink="/riepilogo" routerLinkActive="active"
            class="nav-item nav-link">Riepilogo</a>
        <a
          mat-list-item
          *ngIf="account?.ruolo==0"
          target="_blank"
          [href]="version?.wpsUrl">
          Driver e indicatori
        </a>
        <!-- Menu Superhero -->
        <a
          mat-list-item
          *ngIf="account?.ruolo===20"
          routerLink="/config/edizioni"
          routerLinkActive="active"
          class="nav-item nav-link">
          Gestione Edizioni
        </a>
        <a
          mat-list-item
          *ngIf="account?.ruolo===20"
          routerLink="/config/atenei"
          routerLinkActive="active"
          class="nav-item nav-link">
          Gestione Atenei
        </a>
        <a
          mat-list-item
          *ngIf="account?.ruolo===20"
          routerLink="/config/riepiloghi"
          routerLinkActive="active"
          class="nav-item nav-link">
          Riepilogo Atenei
        </a>
        <a
          mat-list-item
          *ngIf="account?.ruolo===20"
          routerLink="/config/chiusure"
          routerLinkActive="active"
          class="nav-item nav-link">
          Chiusure Atenei
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <alert></alert>
      <app-loader></app-loader>

      <!-- main router outlet -->
      <router-outlet></router-outlet>

      <!-- footer -->
      <nav>
        <app-footer></app-footer>
      </nav>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
