import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AccountService, AlertService, DbService } from '@app/_services';
import { first } from 'rxjs/operators';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChiusuraFormDialog } from './chiusura-form-dialog'


@Component({
  selector: 'abc-riepilogo',
  templateUrl: './riepilogo.component.html',
  styleUrls: ['./riepilogo.component.scss']
})
export class RiepilogoComponent implements OnInit {
  struttureSel = new FormControl();
  responsabileSel = new FormControl();
  struttureList = [];
  graphList =[];
  responsabiliList = [];
  displayedColumns: string[] = ['title', 'num','err','completi','elab','vuoti','completamento'];
  dataSource= new MatTableDataSource([])
  timesheet =[];
  time: {};
  arrayStrutt = [];
  arrayResp = [];
  tot=[];
  account;
  titoloGraph;


  applyFilter() {
    this.arrayStrutt=[];
    this.arrayResp=[];
    if (this.account.ruolo==0){
      if (this.responsabileSel.value!=null &&  this.responsabileSel.value.length>0){
          this.responsabileSel.value.forEach(element => {
            this.arrayResp.push(element._id)
        });
      }
    }
    if (this.struttureSel.value!=null &&  this.struttureSel.value.length>0){
      this.struttureSel.value.forEach(element => {
        this.arrayStrutt.push(element._id)
      });
    }
    this.loadTimesheet();
    this.changeDetectorRefs.detectChanges()
  }
  constructor(private db: DbService,
    private accountService: AccountService,
    public dialog: MatDialog,
    private router: Router,
    private alertService: AlertService,
    private changeDetectorRefs: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.account = this.accountService.accountValue
    this.loadStrutture();
    if (this.account.ruolo==0){
      this.loadResponsabili();
    }
    this.loadTimesheet();
  }
  isNumber(value) {
    return Number.isNaN(value);
  }
  loadTimesheet() {
    this.tot =[];
    this.timesheet=[];
    this.graphList=[];
    this.db.method('timesheet', 'statistiche',{strutture:this.arrayStrutt, responsabili:this.arrayResp})
      .pipe(first())
      .subscribe(data => {
        var interni = data.interni.split(',').map(function(item) {
          return parseInt(item);
      });
        var calcPerc =  interni[0]==0 ? 0 :(interni[2] * 100) / interni[0];
        this.time = {id: 0,
                     titolo:'Interni',
                     totali:interni[0],
                     err:interni[1],
                     completi:interni[2],
                     parziali:interni[3],
                     vuoti:interni[4],
                     perc: calcPerc}
        this.timesheet.push(this.time);
        var collaboratori = data.collaboratori.split(',').map(function(item) {
          return parseInt(item);
      });
        calcPerc =  collaboratori[0]==0 ? 0 :(collaboratori[2] * 100) / collaboratori[0];
        this.time = {id: 1,
                     titolo:'Collaboratori',
                     totali:collaboratori[0],
                     err:collaboratori[1],
                     completi:collaboratori[2],
                     parziali:collaboratori[3],
                     vuoti:collaboratori[4],
                     perc: calcPerc}
        this.timesheet.push(this.time);
        var esterni = data.esterni.split(',').map(function(item) {
          return parseInt(item);
      });
        calcPerc =  esterni[0]==0 ? 0 :(esterni[2] * 100) / esterni[0];
        this.time = {id: 2,
                     titolo:'Esterni',
                     totali:esterni[0],
                     err:esterni[1],
                     completi:esterni[2],
                     parziali:esterni[3],
                     vuoti:esterni[4],
                     perc: calcPerc}
        this.timesheet.push(this.time);
        calcPerc =  interni[0]+collaboratori[0]+ esterni[0]==0 ? 0 :((interni[2]+collaboratori[2]+ esterni[2]) * 100) / (interni[0]+collaboratori[0]+ esterni[0]);
        this.time = {id: 3,
                     titolo: 'Totali',
                     totali: interni[0]+collaboratori[0]+ esterni[0],
                     err: interni[1]+collaboratori[1]+ esterni[1],
                     completi: interni[2]+collaboratori[2]+ esterni[2],
                     parziali: interni[3]+collaboratori[3]+ esterni[3],
                     vuoti: interni[4]+collaboratori[4]+ esterni[4] ,
                     perc: calcPerc
                    }
        this.timesheet.push(this.time);
        this.loadGraph(0);
        this.loadGraph(1);
        this.loadGraph(2);
        this.loadGraph(3);
        this.dataSource = new MatTableDataSource(this.timesheet);
      })
  }

  loadGraph(tipo){
        this.tot = [];
        var graph={doughnutChartData:null,titoloGraph:null, options:null};
        this.tot.push(this.timesheet[tipo].err);
        this.tot.push(this.timesheet[tipo].completi);
        this.tot.push(this.timesheet[tipo].parziali);
        this.tot.push(this.timesheet[tipo].vuoti);
        this.doughnutChartData = [this.tot]
        graph.doughnutChartData = this.doughnutChartData;
        this.titoloGraph = this.timesheet[tipo].titolo;
        graph.options = {
          legend: { position: 'left' },
          title: { display:true, text: this.titoloGraph}
        }
        this.graphList.push(graph)
  }

  loadResponsabili() {
    this.db.readAll('utente', { ruolo: 1 })
      .pipe(first())
      .subscribe(data => {
        this.responsabiliList = data

      })
  }

  loadStrutture() {
    this.db.readAll('struttura')
      .pipe(first())
      .subscribe(data => {
        this.struttureList = data
      })
  }

  chiusura(perc) {
    let dialogConfig = {
      disableClose: true,
      autoFocus: true,
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%",
      data: {}
    }
    var chiusura = {perc: perc}
    let dialogData = { chiusura: chiusura }
    if (chiusura) dialogData.chiusura = { ...chiusura }
    dialogConfig.data = dialogData

    const dialogRef = this.dialog.open(ChiusuraFormDialog, dialogConfig)
    dialogRef.afterClosed()
      .subscribe(chiusura=> {
        if (chiusura) {

            this.accountService
            .chiudi(chiusura.perc, chiusura.nota)
            .pipe(first())
            .subscribe({
              next: () => {
                this.alertService.success('Chiusura effettuata')
                // Faccio reload della route per applicare i nuovi diritti
                this.router.routeReuseStrategy.shouldReuseRoute = () => false
                this.router.navigate([this.router.url])
              },
              error: error => { this.alertService.error(error) }
          })
        }
      })
  }



  doughnutChartLabels: Label[] = ['Errati', 'Completati', 'In Elaborazione','Vuoti'];
  doughnutChartData: MultiDataSet = [this.tot];

  doughnutChartType: ChartType = 'doughnut';
  doughnutChartColor =  [
    {
      backgroundColor: ['rgba(229, 96, 96, 1)', 'rgba(96, 229, 104, 1)', 'rgba(241, 239, 112, 1)','rgba(224, 224, 224, 1)'],
    }
  ];

}
