<button
  *ngIf="!preview || data?.length"
  mat-icon-button color="primary"
  (click)="download()"
  matTooltip="Scarica i dati"
  aria-label="Scarica i dati">
  <mat-icon>arrow_circle_down</mat-icon>
</button>
<button
  mat-icon-button
  color="primary"
  (click)="download_tpl()"
  *ngIf="template?.length && account.ruolo==0"
  matTooltip="Scarica Template"
  aria-label="Scarica template">
  <mat-icon>adjust</mat-icon>
</button>

<div *ngIf="preview" class="xlscontainer">
  <table *ngIf="data?.length">
    <tr>
      <th *ngFor="let col of columns">{{col}}</th>
    </tr>
    <tr *ngFor="let row of data">
      <td *ngFor="let col of columns">
        {{row[col]}}
      </td>
    </tr>
  </table>
</div>
