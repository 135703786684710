<body>
<div style="text-align: center;">
  <input 
  type="file"
  class="hidden"
  id="abcfile"
  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
  (change)="onFileChange($event)" />
<label style="vertical-align: center; background: rgb(236, 233, 233); padding: 10px;" for="abcfile">
  Clicca per selezionare un 
  <span *ngIf="data?.length">
    altro
  </span>
  file  
</label>

<button (click)="upload()" *ngIf="data?.length" mat-raised-button color="primary">
  Carica i dati
</button>

</div>  

<table *ngIf="data?.length">
  <tr>
    <th *ngFor="let col of columns">{{col}}</th>
  </tr>
  <tr *ngFor="let row of data">
    <td *ngFor="let col of columns">
      {{row[col]}}
    </td>
  </tr>
</table>

<button (click)="upload()" *ngIf="data?.length" mat-raised-button color="primary">
  Carica i dati
</button>
</body>
