import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, finalize } from 'rxjs/operators'

//import { environment } from '@environments/environment'
import { Account } from '@app/_models'
import { DbService } from './db.service'

const baseUrl = '/api/auth'

@Injectable({ providedIn: 'root' })
export class AccountService {
  private accountSubject: BehaviorSubject<Account>
  public account: Observable<Account>

  constructor(
    private router: Router,
    private http: HttpClient,
    private db: DbService
  ) {
    this.accountSubject = new BehaviorSubject<Account>(null)
    this.account = this.accountSubject.asObservable()
  }

  public get accountValue(): Account {
    return this.accountSubject.value
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${baseUrl}/login`, { username: email, password: password }, { withCredentials: true })
      .pipe(map(account => {
        this.accountSubject.next(account)
        return account
      }))
  }

  logout() {
    this.http.get<any>(`${baseUrl}/logout`).subscribe()
    this.accountSubject.next(null)
    this.router.navigate(['/account/login'])
  }

  checkSession() {
    // console.log('checkSession', this.accountSubject.value)
    return this.http.get<any>(`${baseUrl}/is-authenticated`)
      .pipe(map((res) => {
          if (this.accountSubject.value) {
            // console.log('checkSession 2', this.accountSubject.value)
            return this.accountSubject.value
          }

          this.http.get<any>(`${baseUrl}/current-user`)
            .subscribe(account => {
              this.accountSubject.next(account)
              // console.log('checkSession 3', this.accountSubject.value)
              // console.log('checkSession 4', account)
              return account
            })
        }))
  }

  register(account: Account) {
    return this.http.post(`${baseUrl}/register`, account)
  }

  verifyEmail(token: string) {
    return this.http.post(`${baseUrl}/verify-email`, { token })
  }

  forgotPassword(email: string) {
    return this.http.post(`${baseUrl}/forgot-password`, { email })
  }

  validateResetToken(token: string) {
    return this.http.post(`${baseUrl}/validate-token`, { token })
  }

  resetPassword(token: string, password: string, confirmPassword: string) {
    return this.http.post(`${baseUrl}/reset-password`, { token, password, confirmPassword })
  }

  getAll() {
    return this.http.get<Account[]>(baseUrl)
  }

  getById(id: number) {
    return this.db.read('utente', id)
  }

  create(params) {
    return this.http.post(baseUrl, params)
  }

  update(id, params) {
    return this.http.put(`${baseUrl}/${id}`, params)
      .pipe(map((account: any) => {
        // update the current account if it was updated
        if (account._id === this.accountValue._id) {
          // publish updated account to subscribers
          account = { ...this.accountValue, ...account }
          this.accountSubject.next(account)
        }
        return account
      }))
  }

  simula(id) {
    return this.db.method('utente', 'simula', { user: id })
      .pipe(map((account: any) => {
        // update the current account if it was updated
        account = { ...this.accountValue, ...account }
        this.accountSubject.next(account)
        return account
      }))
  }

  delete(id: number) {
    return this.http.delete(`${baseUrl}/${id}`)
      .pipe(finalize(() => {
        // auto logout if the logged in account was deleted
        if (id === this.accountValue._id)
          this.logout()
      }))
  }

  chiudi(perc, note) {
    return this.db.method('ateneo', 'chiudi', {perc: perc, nota:note})
      .pipe(map((data: any) => {
        this.accountValue.dt_chiusura = data.dt_chiusura;
      }))
  }
}
