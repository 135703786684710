<div class="content-padding">
  <mat-grid-list cols="10" rowHeight="5:12">
    <mat-grid-tile colspan='7'>
      <mat-card class="card-class card-firstrow">
        <button
          class="pull-right"
          mat-icon-button
          *ngIf="account.ruolo===20"
          matTooltip="Modifica contenuti"
          (click)="editHome(notizie[0])">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title class="card1-title">
            <span>{{notizie[0]?.titolo}}</span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div [innerHTML]="notizie[0]?.testo"></div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile colspan='3'>
      <mat-card class="card-class card-firstrow">
        <button
            class="pull-right"
            mat-icon-button
            *ngIf="account.ruolo===20"
            matTooltip="Aggiungi File"
            (click)="openUpload()">
            <mat-icon>add</mat-icon>
          </button>
        <mat-card-content class="card2">

          <mat-list>
            <mat-list-item *ngFor="let doc of docs">
              <a routerLink="" (click)="scarica(doc)">
                {{doc.descrizione}}
              </a>
              <button *ngIf="account.ruolo===20"  matTooltip="Elimina documento" mat-icon-button (click)="deleteDoc(doc._id)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-list-item>
          </mat-list>

        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="10" rowHeight="7:15">
    <mat-grid-tile colspan="7">
      <mat-card class="card-class">
        <mat-card-header>
          <mat-card-title class="card3-title">
            News e Comunicazioni
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <button
            class="pull-right"
            mat-icon-button
            *ngIf="account.ruolo===20"
            matTooltip="Modifica contenuti"
            (click)="editHome(notizie[2])">
            <mat-icon>edit</mat-icon>
          </button>
          <div>
            {{notizie[2]?.data_inizio| date : 'dd/MM/yyyy'}}
            <span *ngIf="notizie[2]?.data_fine">
              - {{notizie[2]?.data_fine| date : 'dd/MM/yyyy'}}
            </span>
            <br />
          </div>
          <span class="card3-title-event color-primary">{{notizie[2]?.titolo}}</span>
          <br />
          <div [innerHTML]="notizie[2]?.testo"></div>
          <mat-divider></mat-divider>
          <button
            class="pull-right"
            mat-icon-button
            *ngIf="account.ruolo===20"
            matTooltip="Modifica contenuti"
            (click)="editHome(notizie[3])">
            <mat-icon>edit</mat-icon>
          </button>
          <div>
            {{notizie[3]?.data_inizio| date : 'dd/MM/yyyy'}}
            <span *ngIf="notizie[3]?.data_fine">
              - {{notizie[3]?.data_fine| date : 'dd/MM/yyyy'}}
            </span>
            <br />
          </div>
          <span class="card3-title-event color-primary">{{notizie[3]?.titolo}}</span><br />
          <div [innerHTML]="notizie[3]?.testo"></div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile colspan='3'>
      <mat-card class="card-class card4 bg-primary align-items-center">
        <mat-card-content class="card4-content">
          <span class="card4-title">{{notizie[1]?.titolo}}</span>
          <button
            class="pull-right"
            mat-icon-button
            *ngIf="account.ruolo===20"
            matTooltip="Modifica contenuti"
            (click)="editHome(notizie[1])">
            <mat-icon>edit</mat-icon>
          </button>
          <br /><br />
          <div [innerHTML]="notizie[1]?.testo"></div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
