import { NgModule } from '@angular/core'
import { CommonModule, DatePipe } from '@angular/common'
import { ConfirmationDialog, XlsLoaderComponent, XlsDownloaderComponent } from '@app/_components'
import { MaterialModule } from '@app/material.module';
import { LoaderComponent } from './_components/loader/loader.component'
import { LoaderService } from './_services/loader.service'
@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [
    ConfirmationDialog,
    XlsLoaderComponent,
    XlsDownloaderComponent,
    LoaderComponent
  ],
  exports: [
    XlsLoaderComponent,
    XlsDownloaderComponent,
    LoaderComponent
  ],
  entryComponents: [ConfirmationDialog],
  providers: [LoaderService, DatePipe]
})
export class SharedModule { }
