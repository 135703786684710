<h2 mat-dialog-title>Upload File</h2>

<mat-dialog-content>
  <form [formGroup]="abcForm" ng-submit="upload()">
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Descrizione</mat-label>
      <input
      matInput
      placeholder="Descrizione"
      id="abcFileDesc"
      formControlName="abcFileDesc"
      required
      maxlength="200" />
    </mat-form-field>

    <mat-form-field class="columns">
      <mat-label class="label-style" *ngIf="fileSelected;">{{selectedFiles.item(0).name}}</mat-label>
      <mat-label class="label-style" *ngIf="!fileSelected;">Scegli un file</mat-label>
      <input matInput disabled>
      <button
          class="pull-right"
          mat-icon-button
          matTooltip="Allega un file"
          (click)="fileInput.click()">
          <mat-icon>attach_file</mat-icon>
        </button>

      <input hidden
              formControlName="abcFile"
              accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.oasis.opendocument.text"
              (change)="selectFile($event)"
               #fileInput
               type="file"
                id="file">
  </mat-form-field>
  </form>
  </mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="dismiss()">Annulla</button>
  <button
      (click)="upload()"
      [disabled]="!abcForm.valid || !fileSelected"
      mat-raised-button color="primary">
      Carica il documento
    </button>
</mat-dialog-actions>
<app-loader></app-loader>
