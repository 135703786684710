import { Component, Output, EventEmitter } from '@angular/core'
import * as XLSX from 'xlsx'

@Component({
  selector: 'xls-loader',
  templateUrl: 'xls-loader.component.html',
  styleUrls: ['xls-loader.component.css']
})

export class XlsLoaderComponent {
  @Output() uploadFile = new EventEmitter()
  data: any
  columns = []

  constructor() { }

  onFileChange(ev) {
    let workBook = null
    let jsonData = null
    const reader = new FileReader()
    const file = ev.target.files[0]
    reader.onload = () => {
      const data = reader.result
      workBook = XLSX.read(data, { type: 'binary' })
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name]
        initial[name] = XLSX.utils.sheet_to_json(sheet, { defval: "" })
        return initial
      }, {})
      this.data = jsonData[Object.keys(jsonData)[0]]
      this.columns = Object.keys(this.data[0])
      ev.srcElement.value = null
    }
    reader.readAsBinaryString(file)
  }

  upload() {
    this.uploadFile.emit(this.data)
    this.data = []
    this.columns = []
  }
}
