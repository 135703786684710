import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { HomeComponent } from './home'
import { AuthGuard } from '@app/_helpers'
import { RiepilogoComponent } from './riepilogo/riepilogo.component'
import { CookieconsentComponent } from './cookieconsent/cookieconsent.component'

const accountModule = () => import('./account/account.module').then(x => x.AccountModule)
const timesheetModule = () => import('./timesheet/timesheet.module').then(x => x.TimesheetModule)
const configModule = () => import('./config/config.module').then(x => x.ConfigModule)
const gestioneModule = () => import('./gestione/gestione.module').then(x => x.GestioneModule)

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'account', loadChildren: accountModule },
  { path: 'cookieconsent',  component: CookieconsentComponent },
  { path: 'timesheet', loadChildren: timesheetModule, canActivate: [AuthGuard] },
  { path: 'config', loadChildren: configModule, canActivate: [AuthGuard], data: { roles: [20] }  },
  { path: 'gestione', loadChildren: gestioneModule, canActivate: [AuthGuard], data: { roles: [0,1] } },
  { path: 'riepilogo', component: RiepilogoComponent, canActivate: [AuthGuard], data: { roles: [0, 1] } },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
