<h2 mat-dialog-title>{{account.nome}} {{account.cognome}}</h2>
<br/><br/>
<mat-dialog-content>
    Username: <b> {{account.username}}</b><br/>
    <mat-divider></mat-divider>
    Ruolo: <b>
      <span *ngIf="account.ruolo==0">Amministratore</span>
      <span *ngIf="account.ruolo==1">Responsabile</span>
      <span *ngIf="account.ruolo==2">Compilatore</span>
      <span *ngIf="account.ruolo==3">Collaboratore</span>
      <span *ngIf="account.ruolo==4">Esterno</span>
      <span *ngIf="account.ruolo==20">Superhero</span>
    </b>    <br/>

    <mat-divider></mat-divider>
    Ateneo: <b>{{account.ateneo}}</b>
    <br/>

    <mat-divider></mat-divider>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" mat-dialog-close>Chiudi</button>
</mat-dialog-actions>
