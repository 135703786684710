
<div class="fixActionRow">
  <h2 mat-dialog-title>Simula utente</h2>
<mat-dialog-content class="dialog-content-height">

  <mat-dialog-actions *ngIf="account.ruolor"  style="margin-bottom: 5px;">
    <button mat-raised-button color="primary" (click)="simula(0)">Interrompi simulazione</button>
  </mat-dialog-actions>

  <mat-form-field style="width: 100%; margin-bottom: 10px;">
    <mat-label>Cerca un utente... (almeno 3 caratteri)</mat-label>
    <input
      matInput
      [ngModel]="searchText"
      (ngModelChange)="searchModelChanged.next($event)"
      placeholder="Cerca un utente..." />
  </mat-form-field>

  <table mat-table class="full-width-table" [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let col of tableCols" matColumnDef="{{col.def}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{col.desc}}</th>
      <td mat-cell *matCellDef="let row">{{row[col.def]}}</td>
    </ng-container>
    <ng-container matColumnDef="ruolo">
      <th mat-header-cell *matHeaderCellDef>Ruolo</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.ruolo==0">Amministratore</span>
        <span *ngIf="row.ruolo==1">Responsabile</span>
        <span *ngIf="row.ruolo==2">Compilatore</span>
        <span *ngIf="row.ruolo==3">Collaboratore</span>
        <span *ngIf="row.ruolo==4">Esterno</span>
        <span *ngIf="row.ruolo==20">Superhero</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="sel">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button matTooltip="Seleziona" (click)="simula(row.id_utente)">
          <mat-icon>check_circle_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length"></td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
</mat-dialog-content>
<div class="spacer"></div>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" mat-dialog-close>Chiudi</button>
</mat-dialog-actions>
</div>
<app-loader></app-loader>
