import { Injectable } from '@angular/core'
import { DatePipe } from '@angular/common'

@Injectable({ providedIn: 'root' })
export class DateUtils {

  constructor(private dp: DatePipe) {}

  public formatDate(date: Date) {
    if (!date) return null
    return this.dp.transform(date, 'dd/MM/yyyy')
  }

  public stringToDate(date: string) {
    if (!date) return null
    let parts = date.split('/')
    return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]))
  }
}
