import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { ChartsModule } from 'ng2-charts'
import { AccountService } from './_services'
import { AlertComponent } from './_components'
import { AppComponent } from './app.component'
import { UserDialogComponent } from './user-dialog.component'
import { LogsDialogComponent } from './logs-dialog.component'
import { SimulaDialogComponent } from './simula-dialog.component'
import { AppRoutingModule } from './app-routing.module'
import { ErrorInterceptor, appInitializer } from './_helpers'
import { FooterComponent } from './footer/footer.component'
import { HomeComponent } from './home'

import { MaterialModule } from './material.module'
import { NewsFormDialog } from './home/news-form-dialog'
import { UploadDialog } from './home/upload-dialog'
import { SharedModule } from './shared.module'
import { RiepilogoComponent } from './riepilogo/riepilogo.component'
import { ChiusuraFormDialog } from './riepilogo/chiusura-form-dialog'
import localeIt from '@angular/common/locales/it'
import { registerLocaleData } from '@angular/common'
registerLocaleData(localeIt)

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ChartsModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    FooterComponent,
    UserDialogComponent,
    LogsDialogComponent,
    SimulaDialogComponent,
    NewsFormDialog,
    UploadDialog,
    RiepilogoComponent,
    ChiusuraFormDialog
  ],
  entryComponents: [
    UserDialogComponent,
    LogsDialogComponent,
    SimulaDialogComponent,
    NewsFormDialog,
    ChiusuraFormDialog,
    UploadDialog
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: LOCALE_ID, useValue: 'it-IT'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
