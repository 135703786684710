import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-cookieconsent',
  templateUrl: 'cookieconsent.component.html',
  styleUrls: ['cookieconsent.component.css']
})
export class CookieconsentComponent implements OnInit {
  constructor() { }

  ngOnInit() {
    
  }
}
